import background from '../assets/PHEMORE.jpg'

function EE(){
        return(
            <div>
                <h1>SEED ME PHEMORE</h1>
                <img alt="SEED ME PHEMORE!!!" src={background}/>
            </div>
        );
}

export default EE;
